// src/components/Header.js

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Header.css';
import { useTranslation } from 'react-i18next';



const Header = () => {
  const location = useLocation();
  const isActiveRoute = (path) => {
    return location.pathname == path ? 'active' : '';
  };
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  return (
    <header className="app-header">
      <div className="app-section">
        <div className="header-content">
          <Link to="/" className="logo" >
            <img
              className="logo-img"
              src={
                "logo2.png"
              }
              alt="Logo"
            />
            <span className="logo-text">Altair&nbsp;X</span>
          </Link>
          <nav className="nav-tabs">
            <Link to="/" className={`tab ${isActiveRoute('/')}`} id="tab1">{t('home')}</Link>
            <Link to="/products" className={`tab ${isActiveRoute('/products')}`} id="tab2">{t('ourProducts')}</Link>
            <Link to="/about" className={`tab ${isActiveRoute('/about')}`} id="tab3">{t('aboutUs')}</Link>
            <Link to="/contact" className={`tab ${isActiveRoute('/contact')}`} id="tab4">{t('contact')}</Link>
            <div className="language-switcher">
              <span onClick={() => changeLanguage('en')} className='language-tab' id="tab5">EN</span>
              <span className="separator">/</span>
              <span onClick={() => changeLanguage('cn')} className='language-tab' id="tab6">CN</span>
            </div>
          </nav>
          <a className="iconfont icon-cebianlan" onClick={handleClick}></a>
        </div>
      </div>
      <div className={`panel transition ${isClicked ? 'active' : ''}`} id="panel">
        <div className="panel-content">
          <div className="panel-header">
            <span className="iconfont icon-guanbi panel-close" onClick={handleClick}></span>
          </div>
          <ul className="panel-nav" id="navbarNav">
            <li className={`menu-item ${isActiveRoute('/')}`}>
              <i className="menu-icon-arrow"></i>
              <Link to="/" className="menu-title" id="tab1" onClick={handleClick}>{t('home')}</Link>
            </li>
            <li className={`menu-item ${isActiveRoute('/products')}`}>
              <i className="menu-icon-arrow"></i>
              <Link to="/products" className="menu-title" id="tab2" onClick={handleClick}>{t('ourProducts')}</Link>
            </li>
            <li className={`menu-item ${isActiveRoute('/about')}`}>
              <i className="menu-icon-arrow"></i>
              <Link to="/about" className="menu-title" id="tab3" onClick={handleClick}>{t('aboutUs')}</Link>
            </li>
            <li className={`menu-item ${isActiveRoute('/contact')}`}>
              <i className="menu-icon-arrow"></i>
              <Link to="/contact" className="menu-title" id="tab4" onClick={handleClick}>{t('contact')}</Link>
            </li>
          </ul>
          <div className="panel-icon">
            <div className="language-switcher">
              <span onClick={() => changeLanguage('en')} className='language-tab' id="tab5">EN</span>
              <span className="separator">/</span>
              <span onClick={() => changeLanguage('cn')} className='language-tab' id="tab6">CN</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
