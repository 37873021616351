// src/pages/About.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './About.css';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className='app-main'>
      <div className="about-content">
        <div className='app-section'>
          <div className="about-text">
            <div className="about-title">
              <p>{t('aboutVision')}</p>
              <p>{t('aboutVisionDetails')}</p>
            </div>
            <div className="about-info">
              <p>{t('aboutTitle')}</p>
              <p>{t('aboutFounded')}</p>
              <div>{t('aboutMainFeatures')}</div>
              <div className='about-list'>
                <dl className='about-item'>
                  <dt><img
                    className="about-icon"
                    src="add.png"
                  /></dt>
                  <dd>-{t('aboutFeature1')}</dd>
                </dl>
                <dl className='about-item'>
                  <dt><img
                    className="about-icon"
                    src="add.png"
                  /></dt>
                  <dd>-{t('aboutFeature2')}</dd>
                </dl>
                <dl className='about-item'>
                  <dt><img
                    className="about-icon"
                    src="add.png"
                  /></dt>
                  <dd>-{t('aboutFeature3')}</dd>
                </dl>
                <dl className='about-item'>
                  <dt><img
                    className="about-icon"
                    src="add.png"
                  /></dt>
                  <dd>-{t('aboutFeature4')}</dd>
                </dl>
              </div>
              <p>{t('aboutHeadquartered')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default About;
