// src/pages/Products.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleCarousel from '../components/SimpleCarousel';
import './Products.css';

const Products = () => {
  const { t } = useTranslation();

  const texts = [
    { title: t('productsEmotionTitle'), content: t('productsEmotionContent') },
    { title: t('productsOmniTitle'), content: t('productsOmniContent') },
    { title: t('productsIntelligenceTitle'), content: t('productsIntelligenceContent') }
  ];

  return (
    <div className='app-main'>
      <div className='app-section'>
        <div className="product-content">
          <div className="product-carousel">
            <SimpleCarousel />
          </div>
          <div className="product-rt">
            <div className="product-list">
              {texts.map((text, index) => (
                <div key={index} className="product-item">
                  <h4 className="product-title">{text.title}</h4>
                  <p className="product-text">{text.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Products;
