// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "home": "Home",
          "ourProducts": "Our Products",
          "aboutUs": "About Us",
          "contact": "Contact",
          "headerTitle": "Your Header Content",
          "vision": "Our vision:",
          "visionDetails": "By 2030, become the world's leading interactive virtual world for agents",
          "aboutUs": "About Us",
          "founded": "Founded in 2024, Altair X is committed to bringing positive change to the world with cutting-edge AI technology, creating a second virtual world for users.",
          "mainFeatures": "Our main technical features include:",
          "feature1": "Multimodal sentiment analysis technology (e.g. facial expression, speech, text, heart rate, breathing, etc.)",
          "feature2": "Sentiment analysis algorithms (e.g. SentiWordNet)",
          "feature3": "Electroencephalogram Emotion Recognition (EEG)",
          "feature4": "Create the most natural connection track with AR technology",
          "headquartered": "Headquartered in Singapore spread across global offices in China, Japan, Korea, the United States.",
          "carouselText1": "Based on the large language model, it uses the latest language/speech/video generation technology including GPT, Diffusion, etc., and makes some self-research and improvement. Modal interconnection and model self-iteration are realized in the bottom layer.",
          "carouselText2": "Based on the large language model, it uses the latest language/speech/video generation technology including GPT, Diffusion, etc., and makes some self-research and improvement. Modal interconnection and model self-iteration are realized in the bottom layer.",
          "carouselText3": "Based on the large language model, it uses the latest language/speech/video generation technology including GPT, Diffusion, etc., and makes some self-research and improvement. Modal interconnection and model self-iteration are realized in the bottom layer.",
          "homeIntroduction": "Introduction",
          "homeParagraph": "A technology pioneer committed to changing the world through innovative AI technology. Our mission is to develop intelligent products that empower users with endless possibilities and creativity. Our goal is to create a dynamic, interactive and creative virtual world.",
          "aboutVision": "Our vision:",
          "aboutVisionDetails": "By 2030, become the world's leading interactive virtual world for agents",
          "aboutTitle": "About Us",
          "aboutFounded": "Founded in 2024, Altair X is committed to bringing positive change to the world with cutting-edge AI technology, creating a second virtual world for users.",
          "aboutMainFeatures": "Our main technical features include:",
          "aboutFeature1": "Multimodal sentiment analysis technology (e.g. facial expression, speech, text, heart rate, breathing, etc.)",
          "aboutFeature2": "Sentiment analysis algorithms (e.g. SentiWordNet)",
          "aboutFeature3": "Electroencephalogram Emotion Recognition (EEG)",
          "aboutFeature4": "Create the most natural connection track with AR technology",
          "aboutHeadquartered": "Headquartered in Singapore spread across global offices in China, Japan, Korea, the United States.",
          "productsEmotionTitle": "Emotion",
          "productsEmotionContent": "With emotion analysis technology at its core, the EmOAI system can better analyze users’ emotions and predict their emotional development, thereby generating higher quality responses and guiding the direction of dialogue interaction.",
          "productsOmniTitle": "Omni",
          "productsOmniContent": "The EmOAI system can interact with users in an all-encompassing manner, not limited to text, voice, images, and video. We have broken down barriers between different modalities providing users with a more comprehensive interactive experience.",
          "productsIntelligenceTitle": "Intelligence",
          "productsIntelligenceContent": "The EmOAI system is an intelligent system that can develop and upgrade based on interactions with users over time. With increased interaction, it gains more information and a better understanding of the user, ultimately becoming a companion that feels like a very good friend to each individual.",
          "contactDefaultText1": "Our Values",
          "contactDefaultText2": "Work at Altair x",
          "contactAlternateText1_1": "Pursuit of perfection",
          "contactAlternateText1_2": "Privacy is Paramount",
          "contactAlternateText1_3": "Respect every spiritual world",
          "contactAlternateText2_1": "Problem-driven workplace culture",
          "contactAlternateText2_2": "Learning and development",
          "joinUs": "Join Us",
        }
      },
      cn: {
        translation: {
          "home": "首页",
          "ourProducts": "我们的产品",
          "aboutUs": "关于我们",
          "contact": "联系我们",
          "headerTitle": "你的标题内容",
          "vision": "我们的愿景：",
          "visionDetails": "到2030年，成为全球领先的代理虚拟互动世界",
          "aboutUs": "关于我们",
          "founded": "Altair X 成立于2024年，致力于通过尖端的AI技术为世界带来积极的改变，为用户创造第二个虚拟世界。",
          "mainFeatures": "我们的主要技术特点包括：",
          "feature1": "多模式情感分析技术（例如面部表情、语音、文本、心率、呼吸等）",
          "feature2": "情感分析算法（例如SentiWordNet）",
          "feature3": "脑电图情感识别（EEG）",
          "feature4": "通过AR技术创建最自然的连接轨迹",
          "headquartered": "总部位于新加坡，全球办公室遍布中国、日本、韩国和美国。",
          "carouselText1": "基于大型语言模型，使用最新的语言/语音/视频生成技术，包括GPT、扩散等，并进行一些自主研究和改进。在底层实现模态互联和模型自迭代。",
          "carouselText2": "基于大型语言模型，使用最新的语言/语音/视频生成技术，包括GPT、扩散等，并进行一些自主研究和改进。在底层实现模态互联和模型自迭代。",
          "carouselText3": "基于大型语言模型，使用最新的语言/语音/视频生成技术，包括GPT、扩散等，并进行一些自主研究和改进。在底层实现模态互联和模型自迭代。",
          "homeIntroduction": "简介",
          "homeParagraph": "一家致力于通过创新的人工智能技术改变世界的技术先锋。我们的使命是开发智能产品，使用户拥有无限的可能性和创造力。我们的目标是创建一个动态、互动和创造性的虚拟世界。",
          "aboutVision": "我们的愿景：",
          "aboutVisionDetails": "到2030年，成为全球领先的代理虚拟互动世界",
          "aboutTitle": "关于我们",
          "aboutFounded": "Altair X 成立于2024年，致力于通过尖端的AI技术为世界带来积极的改变，为用户创造第二个虚拟世界。",
          "aboutMainFeatures": "我们的主要技术特点包括：",
          "aboutFeature1": "多模式情感分析技术（例如面部表情、语音、文本、心率、呼吸等）",
          "aboutFeature2": "情感分析算法（例如SentiWordNet）",
          "aboutFeature3": "脑电图情感识别（EEG）",
          "aboutFeature4": "通过AR技术创建最自然的连接轨迹",
          "aboutHeadquartered": "总部位于新加坡，全球办公室遍布中国、日本、韩国和美国。",
          "productsEmotionTitle": "情感",
          "productsEmotionContent": "EmOAI系统以情感分析技术为核心，可以更好地分析用户的情感并预测其情感发展，从而生成更高质量的响应并引导对话互动的方向。",
          "productsOmniTitle": "全方位",
          "productsOmniContent": "EmOAI系统可以与用户进行全方位的互动，不限于文本、语音、图像和视频。我们打破了不同模态之间的壁垒，为用户提供了更全面的互动体验。",
          "productsIntelligenceTitle": "智能",
          "productsIntelligenceContent": "EmOAI系统是一个智能系统，可以随着时间的推移根据与用户的互动进行开发和升级。随着互动的增加，它会获得更多的信息和更好的理解用户，最终成为每个人都感觉像是非常好的朋友的伴侣。",
          "contactDefaultText1": "我们的价值",
          "contactDefaultText2": "在Altair x工作",
          "contactAlternateText1_1": "追求完美",
          "contactAlternateText1_2": "隐私至上",
          "contactAlternateText1_3": "尊重每个精神世界",
          "contactAlternateText2_1": "问题驱动的工作文化",
          "contactAlternateText2_2": "学习与发展",
          "joinUs": "加入我们",
        }
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
