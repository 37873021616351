// src/components/SimpleCarousel.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './SimpleCarousel.css';

const SimpleCarousel = () => {
  const { t } = useTranslation();

  const images = [
    'pic6.png',
    'pic7.png',
    'pic8.png',
  ];
  
  const texts = [
    t('carouselText1'),
    t('carouselText2'),
    t('carouselText3')
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // 每3秒切换一次图片

    return () => clearInterval(interval); // 清除定时器
  }, [images.length]);

  return (
    <div className="simple-carousel">
      <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
      <div className="slide-text" id='text1'>
        {texts[currentIndex]}
      </div>
    </div>
  );
};

export default SimpleCarousel;
