import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css'; // 引入自定义样式

const Contact = () => {
  const { t, i18n } = useTranslation();

  const getTexts = () => ([
    {
      default: t('contactDefaultText1'),
      alternate: [t('contactAlternateText1_1'), t('contactAlternateText1_2'), t('contactAlternateText1_3')],
      isDefault: true
    },
    {
      default: t('contactDefaultText2'),
      alternate: [t('contactAlternateText2_1'), t('contactAlternateText2_2')],
      isDefault: true
    }
  ]);

  const [texts, setTexts] = useState(getTexts());

  useEffect(() => {
    setTexts(getTexts());
  }, [i18n.language]);

  const handleRedirectClick = () => {
    window.location.href = 'https://joblist.zeabur.app/'; // Replace with the URL you want to redirect to
  };

  const handleComponentClick = (index) => {
    const newTexts = texts.map((text, idx) => {
      if (idx === index) {
        return { ...text, isDefault: !text.isDefault };
      }
      return text;
    });
    setTexts(newTexts);
  };

  return (
    <div className='app-main'>
      <div className='app-section'>
        <div className="contact-content">
          <div className="contact-lt">
            <img
              src="pic10.png"
              alt="Contact"
              className="img-block"
            />
          </div>
          <div className="contact-rt">
            <button onClick={handleRedirectClick} className="contact-title">
                  {t('joinUs')}
            </button>
            <ul className='contact-list'>
              {texts.map((text, index) => (
                <li
                  key={index}
                  className={`contact-item ${text.isDefault?'':'active'}`}
                  onClick={() => handleComponentClick(index)}
                >
                  <div className="contact-text">
                    {text.isDefault ? (
                      text.default
                    ) : (
                      text.alternate.map((line, i) => <p key={i} className="text-line">{line}</p>)
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Contact;
