// src/pages/Home.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Home.css'; // 引入自定义样式

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="app-main">
      <div className='home-content'>
        <video autoPlay loop muted className="home-video">
          <source src="/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="home-body">
          <div className='app-section'>
            <div className="home-text">
              <div className="home-title">
                {t('homeIntroduction')}
              </div>
              <div className="home-info">
                {t('homeParagraph')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;